<template>
	<div class="h-[100vh] w-full relative overscroll-contain ">
		<div v-if="!isShowToast" class="chatContent  w-full bg-white absolute top-[88px] overscroll-contain">
			<div class="w-[690px] mx-[auto] pt-[60px]  ">
				<div class="flex ">
					<div>
						<div class="text-[40px] text-white font-medium text-left mb-[30px]">
							{{ titles[props.state].title }}
						</div>
						<div class="text-[28px] text-white  text-left">
							{{ titles[props.state].des }}
						</div>
					</div>
					<!-- <div class="relative w-[50px]">
						<div class="absolute top-[-15px] " @click="close">
							<van-icon name="cross" color='white' size="20" />
						</div>
					</div> -->
				</div>
				<!-- @touchmove.prevent -->
				<!-- h-[60vh] overflow-y-scroll  -->
				<div class="forms mt-[50px] pt-[40px]  w-full bg-white overscroll-contain ">
					<van-form @submit="onSubmit" ref="formBox">
						<!-- <van-cell-group inset> -->

						<!-- 您想下载的报告 -->
						<van-field v-if="props.state === 'report'" name="reportId" v-model="reportId" required label="您想下载的报告"
							placeholder="请输入您想下载的报告名称" label-align="top" label-class="labelClass" label-width="100%" disabled
							:rules="[{ required: props.state === 'report', message: '请填写您想下载的报告名称' }]" />
						<!-- 选择体验产品 -->
						<van-field v-else v-model="keywordIdName" name="keywordIdName" is-link readonly @click="showPicker = true" required
							:label="titles[props.state].labelTitle" label-align="top" label-class="labelClass" label-width="100%"
							:rules="[{ required: true, message: titles[props.state].labelTitle }]" placeholder="请选择" />
						<van-popup v-model:show="showPicker" position="bottom">
							<van-picker :columns="optionList" :columns-field-names="option" @confirm="onConfirm"
								@cancel="showPicker = false" />
						</van-popup>

					

						<!--您的姓名 -->
						<van-field v-model="name" required   label="您的姓名" name="name" label-align="top"
							label-class="labelClass" label-width="100%" placeholder="请输入您的姓名"
							:rules="[{ required: true, message: '请填写您的姓名' }]" />
						<van-field v-model="mobile" required name="mobile"   type="tel" label="您的手机号" placeholder="请输入手机号" label-align="top"
							label-class="labelClass" label-width="100%"
							:rules="[{ required: true, message: '请填写您的手机号' },
							{ required: true, pattern: /^1[3456789]\d{9}$/, message: '请填写您的手机号' }]" />
						<van-field v-model="verifyCode" required center  label="验证码"  type="number" name="verifyCode" placeholder="请输入短信验证码"
							label-align="top" label-class="labelClass " class="codeBtn" label-width="100%"
							:rules="[{ required: true, message: '请填写验证码' }]">
							<template #button>
								<van-button size="small" type="primary" @click="sentCode"	>	 {{ timer === 0 ? '发送验证码' : `${timer}s` }}</van-button>
							</template>
						</van-field>


						<!-- 您的单位类型 -->
						<van-field v-model="unitTypeName" name="unitTypeName" is-link readonly @click="flatTypeShow = true" required
							label="您的单位类型" placeholder='请选择' label-align="top" label-class="labelClass" label-width="100%"
							:rules="[{ required: true, message: '请选择您的单位类型' }]" />
						<van-popup v-model:show="flatTypeShow" position="bottom">
							<van-picker :columns="companyList" @confirm="flatTypeOnConfirm" @cancel="flatTypeShow = false"
								:columns-field-names="company" />
						</van-popup>
						<!-- 您的单位名称 -->

						<van-field v-model="unitName" name="unitName" required label="您的单位名称" placeholder="请输入您的单位名称"
							label-align="top" label-class="labelClass" label-width="100%"
							:rules="[{ required: true, message: '请填写您的单位名称' }]" />
						<!-- 您的职务 -->
						<van-field v-model="position" name="position" label="您的职务" placeholder="请输入您的职务" label-align="top"
							label-class="labelClass" label-width="100%" />
						<!-- 您的邮箱 -->
						<van-field v-model="email" name="email" label="您的邮箱" placeholder="请输入您的邮箱" label-align="top"
							label-class="labelClass" label-width="100%" />
						<!-- 备注 -->
						<van-field v-model="remark" name="remark" rows="4" autosize label="备注" type="textarea" maxlength="200"
							placeholder="请输入您的详细问题和需求，不超过200字" show-word-limit label-align="top" label-class="labelClass"
							label-width="100%" />
						<van-field name="checkbox" label="">
							<template #input>
								<span class="text-[#ee0a24] text-[28px] pr-[1vw] fontFamily ]">*</span>  <van-checkbox v-model="isChecked" shape="square" name="isChecked"
								 
								><span class="text-[25px] leading-5 text-[#999]">我愿意通过电子邮件，接收来自艾瑞数云的产品和资讯信息。</span></van-checkbox>

							</template>
						</van-field>
						<!-- </van-cell-group> -->
						<div class="w-full flex mb-[65px] mt-[40px]">
							<van-button native-type="submit" class="submitBtn"> {{ titles[props.state].buttonEmits }}</van-button>
						</div>


					</van-form>
				</div>
			</div>
		</div>
		<div v-else class="bg-black/70  h-[100vh] w-full relative overscroll-contain ">
		<van-toast class="showToastClass" v-model:show="isShowToast" style="{padding: 0,background-color: 'white';}">
			<template #message>
				<div class=" w-[500px] flex justify-center  items-center flex-wrap pt-3 pb-3">
					<van-image :src="image" width="40" height="40" style="display: block" />
					<div class="w-full text-[20px] text-[#666] pt-[30px] ">
						<div class="text-[#0a69f6] text-[30px] font-medium">
							提交成功
						</div>
						<div class=" text-[10px] pt-3 leading-5">
							运营顾问将会在3个工作日内与您联系
						</div>
					</div>
				</div>


			</template>
		</van-toast>
	</div>
	</div>
</template>
<script setup>
import { ref, defineProps, defineEmits, onMounted } from 'vue'
import { sendCode, perFix } from '@/api/index'
import axios from 'axios'
import { showToast, Checkbox, CheckboxGroup } from 'vant';
import { useIntervalFn } from '@vueuse/core'
import image from '../../assets/imgs/success.png'
const props = defineProps([ 'state'])
watch(()=> props.state,(newValue)=>{
	console.log(newValue)
	timer.value=0
	keywordId.value=''
	name.value=''
	mobile.value=''
	verifyCode.value=''
	unitType.value=''
	unitName.value=''
	position.value=''
	email.value=''
	remark.value=''
	reportId.value=''
	unitTypeName.value=''
	keywordIdName.value=''
	getOptionList()
	getCompanyList()
	reportId.value= sessionStorage.getItem('reportName')
 })
const titles = {
	report: {
		id: 4,
		title: '产业报告获取',
		des: '请完善以下信息，以便我们及时将产业报告推送给您',
		url: '/admin/industry/report/record/add',
		optionListId: null,
		msg: '提交成功，运营顾问将在3个工作日内与您联系',
		buttonEmits: "立即获取",
		labelTitle:''

	},
	experience: {
		id: 2,
		title: '预约产品体验',
		des: '请完善以下信息，我们的运营顾问会在3个工作日内与您联系。',
		url: '/admin/product/experience/add',
		optionListId: 4,
		msg: '提交成功，运营顾问将在3个工作日内与您联系',
		buttonEmits: "立即预约",
		labelTitle:'选择体验产品'
	},
	consult: {
		id: 3,
		title: '咨询相关业务',
		des: '请完善以下信息，我们的运营顾问会在3个工作日内与您联系。',
		url: '/admin/consult/business/add',
		optionListId: 5,
		msg: '提交成功，运营顾问将在3个工作日内与您联系',
		buttonEmits: "立即咨询",
		labelTitle:'选择咨询业务'

	}
}
// const image=ref(image)
const isShowForm = ref(true)
const isShowToast = ref(false)
const isChecked = ref(true)
// const formBox = ref({
// 	"reportId": '',
// 	"keywordIdName": '',    //咨询类型关键字id
// 	"name": "",   //提交人名称
// 	"mobile": "",  // 提交人手机号
// 	"verifyCode": "",  // 验证码
// 	"unitTypeName": '',   // 单位类型：1政府 2园区 3机构 4企业 5其他
// 	"unitName": "",  //单位名称
// 	"position": "",  // 提交人职务
// 	"email": "",   // 提交人邮箱
// 	"remark": ""  // 提交人备注
// });
const timer = ref(0)
const keywordId = ref('');
const name = ref('');
const mobile = ref('');
const verifyCode = ref('');
const unitType = ref('');
const unitName = ref('');
const position = ref('');
const email = ref('');
const remark = ref('');
const reportId = ref('');
const flatTypeShow = ref(false);
const showPicker = ref(false);
const optionList = ref([  { text: '杭州', value: 'Hangzhou' },
		])
const companyList = ref([
]);
const unitTypeName = ref('');
const keywordIdName = ref('');

const company = {
	text: 'description', value: 'code',
}
const option = {
	text: 'name', value: 'id',
}
const emit = defineEmits(['close',])
onMounted(() => {
	getOptionList()
	getCompanyList()
	reportId.value= sessionStorage.getItem('reportName')
})
// 发送验证码倒计时
// useIntervalFn(定时的回调，回调的时间间隔，控制回调的调用方式)
// 参数三 {immediate: true, immediateCallback: false}
// 3-1) immediate 首次运行useIntervalFn函数时，是否立刻开启定时任务（默认值true表示默认开启）
// 3-2）immediateCallback 执行useIntervalFn函数立刻执行回调（在延时时间的前或者后调用），默认值是false，如果修改为true，表示为不延时，立刻启动定时任务（不要使用pause方法）
// pause暂停；resume启动
const { pause, resume } = useIntervalFn(
	() => {
		if (timer.value <= 0) {
			// 停止定时任务
			pause()
		} else {
			// 单次定时任务执行的回调
			timer.value--
		}
	},
	1000,
	{
		// 默认不开启定时任务
		immediate: false
	}
)

const onConfirm = ({ selectedOptions }) => {
	keywordIdName.value = selectedOptions[0]?.name;
	keywordId.value = selectedOptions[0]?.id;
	showPicker.value = false;
};
const flatTypeOnConfirm = ({ selectedOptions }) => {
	unitTypeName.value = selectedOptions[0]?.description;
	unitType.value = selectedOptions[0]?.code;
	flatTypeShow.value = false;
};
const onSubmit = (values) => {
	let {
		keywordIdName, unitTypeName,checkbox, ...prop
	} = values
	let data = props.state === 'report' ? {
		...prop,
		reportId: sessionStorage.getItem('reportId'),
		unitType: unitType.value
	} : {
		...prop,
		unitType: unitType.value,
		keywordId: keywordId.value,

	}
	if(values?.verifyCode.length!==6){
		showToast('请填写6位数验证码')
		return
	}
	// console.log(data)
	if (!isChecked.value) {
		showToast('请先同意可通过电子邮件接收来自艾瑞数云的产品和资讯信息');
		return
	}
	let url = titles[props.state].url

	axios.post(`${perFix}${url}`, {
		...data
	})
		.then(function (response) {

			if (response.data.code === 'SUCCESS') {
				isShowToast.value = true
				isShowForm.value = false
				setTimeout(() => {
					close()
				}, 2000)


			} else {
				showToast(response.data.msg,);

			}
		})
};

const sentCode = () => {
	if (mobile.value === '') {

	 showToast('手机号不能为空');

	} else {
			// 发送验证码
			// 开启倒计时效果
			if (timer.value === 0) {
				timer.value = 60
				resume()
			} else {
				return
			}
		axios.post(`${perFix}/admin/sms/log/sendVerificationCode`, {
			// params: {
			mobile: mobile.value,
			type: titles[props.state].id
			// }
		})
			.then(function (response) {

				if (response.data.code === 'SUCCESS') {
					showToast('发送成功')

				} else {
					showToast(response.data.msg)
				}
			})


	}
}

const close = () => {
	emit('close')
}

const getOptionList = () => {
	if (!titles[props.state].optionListId) return;
	axios.get(`${perFix}/admin/keyword/dictionary/listByType?keywordType=${titles[props.state].optionListId}`,)
		.then(function (response) {
			if (response.data.code === 'SUCCESS') {
				optionList.value = response.data.result
			}
		})
	 

}
const getCompanyList = () => {
	axios.get(`${perFix}/admin/keyword/dictionary/listUnitType`,)
		.then(function (response) {
			if (response.data.code === 'SUCCESS') {
				companyList.value = response.data.result
			}
		})
		// .catch(function (error) {

		// 	console.log(error);
		// })
		// .finally(function () {
		// 	// always executed
		// });

}
</script>

<style scoped>
.chatContent {

	overflow-y: scroll;
	border-radius: 20px 20px 0 0;
	background: white top/contain no-repeat url(../../assets/imgs/homes/form_bg.png);
}
.fontFamily{
	padding-top: 4PX;
	font-family: Source Han Sans CN ;
}
.forms {

	border-radius: 10px 10px 0 0;
}

.submitBtn {
	font-size:14PX;
	margin: 0 auto;
	width: 220px;
	height: 80px;
	background: #0A69F6;
	border: 1px solid #FFFFFF;
	border-radius: 40px;
	color: white
}
</style>
<style lang="less">
.chatContent {
	.forms {
		.van-form{
			padding-bottom: 80px;
		}

		.van-cell {
			&::after {
				border: 0px
			}
		}
.van-field__error-message{
	padding-top: 5px;
}
		.labelClass {
			// margin-top: 40px;
			// margin-bottom: 25px;
			// font-size: 28px;
			font-family: Source Han Sans CN;
			// font-weight: 400;
			color: #999999;
			padding-bottom: 10px;
			font-size: 25px;
		}

		textarea {
			font-size: 28px;
			font-family: Source Han Sans CN;
			font-weight: 400;
			color: #4f4f4f;
			background: #F0F4F7;
			border-radius: 4px;
			padding: 10px;
		}

		input {
			padding: 10px;
			font-size: 25px;
			font-family: Source Han Sans CN;
			font-weight: 400;
			color: #4f4f4f;
			// width: 630px;
			height: 80px;
			background: #F0F4F7;
			border-radius: 4px;
		}

		.van-badge__wrapper.van-icon-cross,.van-badge__wrapper.van-icon-arrow {
			position: absolute;
			top: 45PX;
			right: 8vw;
			// width: 10vw !important;
			// height: 10vw  !important;
			&::before{
				font-size: 30px;
				transform: rotate(90deg);
			}
		}

		.codeBtn {
			.van-field__body {
				width: 101%
			}

			.van-field__button {
				.van-button {
					position: absolute;
					border-color: rgba(12, 107, 247, 1);
					color: rgba(10, 105, 246, 1);
					background: transparent;
					padding: 0 30px;
					min-width: 170px;
					right: 20px;
					top: 8px;
					border-radius: 170px;
					height: 65px;
					font-size: 22px;
				}
			}
		}
		.van-badge__wrapper.van-icon,.van-checkbox__icon{
			width:30px;
			height: 30px;

		}
		.van-icon-success:before{
			font-size: 13PX;
			position: absolute;
    left: 0;
		width: 4vw;
    height: 4vw;

		}

	}
}

.showToastClass {
	background: white;
}
.van-toast__text{
	line-height: 2rem;
}
</style>