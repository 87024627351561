<script setup>
import { Checkbox, CheckboxGroup, showToast } from 'vant'

const downloadAnZhuo = () => {
  window.open('https://statictest.idicc.cn/cdn/aiApp/产业AIR_1.0.0.apk')
}
const downloadIos = () => {
  // showToast('敬请期待~')
  window.open('https://apps.apple.com/cn/app/id6737882470')
}
</script>

<template>
  <div class="introduce">
    <div class="introduceBtns">
      <div class="anzhuo" @click="downloadAnZhuo" />
      <div class="ios" @click="downloadIos" />
    </div>
    <van-toast id="van-toast" />
  </div>
</template>

<style lang="less" scoped>
.introduce {
  width: 100%;
  height: 1733px;
  background: url("@/assets/introduce.png") no-repeat;
  background-size: 100% 100%;
  position: relative;
}
.introduceBtns{
  width: 100%;
  padding: 0 60px;
  display: flex;
  justify-content: space-between;
  position: absolute;
  bottom: 280px;
  .ios,.anzhuo{
    width: 46%;
    height: 90px;
  }
  .ios{
    background: url("@/assets/IOS.png") no-repeat;
    background-size: 100% 100%;
  }
  .anzhuo{
    background: url("@/assets/anzhuo.png") no-repeat;
    background-size: 100% 100%;
  }
}
// .ios{
//   background: url("@/assets/IOS.png") no-repeat;
//   background-size: 100% 100%;
// }
// .anzhuo{
//   background: url("@/assets/anzhuo.png") no-repeat;
//   background-size: 100% 100%;
// }
</style>
